<template>
  <v-card>
    <v-card-text v-if="clusterId">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Ziyaretçi Kayıtları"
            icon="mdi-door"
            :add-route="
              can('edit-visitor-log')
                ? { name: 'communication.visitor-logs.create' }
                : null
            "
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :hide-edit="!can('edit-visitor-log')"
            @click:edit="handleEditClick"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
            show-delete
            @click:delete="handleDeleteClick"
            :delete-enabled="selectedItems.length > 0"
          ></rs-table-title-bar>

          <rs-confirm
            ref="confirmDelete"
            :loading="isLoading"
            @confirmed="handleDelete"
            @cancelled="$refs.confirmDelete.hide()"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'communication.visitor-logs.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.visitor_name="{ item, value }">
          <router-link
            :to="{
              name: 'communication.visitor-logs.show',
              params: { id: item.id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.visited_at="{ value }">
          <rs-table-cell-date show-time :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.left_at="{ value }">
          <rs-table-cell-date show-time :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.created_at="{ value }">
          <rs-table-cell-date show-time :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_courier="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.resident_name="{ item, value }">
          <router-link
            :to="{
              name: 'definitions.residents.show',
              params: { id: item.resident_id },
            }"
            v-if="can('see-resident') && item.resident_id"
          >
            {{ value }}
          </router-link>
          <template v-else>{{ value }}</template>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-text v-else>
      Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";
import { mapGetters } from "vuex";

export default {
  name: "VisitorLogList",
  mixins: [filtersToURL, hasExport, hasPermissions],
  computed: {
    ...mapGetters(["clusterId"]),
  },
  data() {
    return {
      options: {
        sortBy: ["created_at"],
        sortDesc: [true],
      },
      dataTableAttrs: {
        footerProps: {
          disableItemsPerPage: false,
        },
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Gelen Kişi",
          value: "visitor_name",
          searchable: "text",
          width: "300px",
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
          width: "200px",
        },
        {
          text: "Giriş",
          value: "visited_at",
          searchable: "date",
        },
        {
          text: "Çıkış",
          value: "left_at",
          searchable: "date",
        },
        {
          text: "İlgili Sakin",
          value: "resident_name",
          searchable: "text",
        },
        {
          text: "Kargo",
          value: "is_courier",
          searchable: "yesNo",
        },
        {
          text: "Kayıt Tarihi",
          value: "created_at",
          searchable: "date",
        },
        {
          text: "Ekleyen",
          value: "added_by_user_name",
          searchable: "text",
        },
      ],
      titleBarAttrs: {
        exportUrl: () => `visitor-logs`,
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.cluster_id = this.clusterId;

      return params;
    },
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query("visitor-logs", { params })
        .then((response) => {
          this.loadListFromResponse(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.selectedItems.length) {
        this.$router.push({
          name: "communication.visitor-logs.edit",
          params: {
            id: this.selectedItems[0].id,
          },
        });
      }
    },
    handleDelete() {
      this.isLoading = true;

      const params = {
        ids: this.selectedItems.map((v) => v.id),
      };

      this.$api
        .delete("visitor-logs", params)
        .then(() => {
          this.$toast.success("Silindi");
          this.selectedItems = [];
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.$nextTick(() => this.loadList());
        });
      this.$refs.confirmDelete.hide();
    },
    handleDeleteClick() {
      this.$refs.confirmDelete.show(
        `${this.selectedItems.length} adet kaydı silmek istediğinizden emin misiniz?`
      );
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }, 250),
      deep: true,
    },
  },
};
</script>
