var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[(_vm.clusterId)?_c('v-card-text',[_c('v-data-table',_vm._b({attrs:{"headers":_vm.headersShown,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.$refs.headSearch)?_c('rs-table-title-bar',_vm._b({attrs:{"title":"Ziyaretçi Kayıtları","icon":"mdi-door","add-route":_vm.can('edit-visitor-log')
              ? { name: 'communication.visitor-logs.create' }
              : null,"hide-edit":!_vm.can('edit-visitor-log'),"edit-enabled":_vm.selectedItems.length === 1,"search":_vm.search.query,"show-delete":"","delete-enabled":_vm.selectedItems.length > 0},on:{"clear-filters":_vm.$refs.headSearch.reset,"reload":_vm.loadList,"click:edit":_vm.handleEditClick,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)},"click:delete":_vm.handleDeleteClick}},'rs-table-title-bar',_vm.titleBarAttrs,false)):_vm._e(),_c('rs-confirm',{ref:"confirmDelete",attrs:{"loading":_vm.isLoading},on:{"confirmed":_vm.handleDelete,"cancelled":function($event){return _vm.$refs.confirmDelete.hide()}}})]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
            name: 'communication.visitor-logs.edit',
            params: { id: item.id },
          }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.visitor_name",fn:function({ item, value }){return [_c('router-link',{attrs:{"to":{
            name: 'communication.visitor-logs.show',
            params: { id: item.id },
          }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.visited_at",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"show-time":"","value":value}})]}},{key:"item.left_at",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"show-time":"","value":value}})]}},{key:"item.created_at",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"show-time":"","value":value}})]}},{key:"item.is_courier",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}},{key:"item.resident_name",fn:function({ item, value }){return [(_vm.can('see-resident') && item.resident_id)?_c('router-link',{attrs:{"to":{
            name: 'definitions.residents.show',
            params: { id: item.resident_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")]):[_vm._v(_vm._s(value))]]}}],null,false,2016913577),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false))],1):_c('v-card-text',[_vm._v(" Listeyi görmek için üst menüden bir toplu yaşam alanı seçin. ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }